/**
 * 用户支付相关请求
 */
import request from '@/utils/request'

/**
 * 获取积分充值选项
 * @returns Array
 */
export const getRechargeList = () => {
  return request.get('/recharge/list')
}

/**
 * 获取等级兑换列表（不包含普通会员0点数）
 * @returns Array
 */
export const getLevelList = () => {
  return request.get('/level/list')
}

/**
 * 积分兑换(影片或等级)
 * @param {Int} id
 *  - movie_id | vip_id
 * @param {Int} type
 *  - 1:影片，2:Vip
 * @returns
 */
export const redemption = (id, type = 2) => {
  return request.post('/user/expend', {
    id,
    type
  })
}

/**
 * 用户购买点数订单
 * @param {object} obj - 购买点数
 *  - {'points':购买点数, 'type':使用的平台支付类型}
 * @returns Response Json
 */
export const orderPoints = (obj) => {
  return request.post('/order/points', {
    ...obj
  }, {
    timeout: 10000
  })
}

/**
 * 获取用户购买VIP订单
 * @param {object} obj - VipID
 *  - {'id':VipID, 'type':使用的平台支付类型}
 * @returns Response Json
 */
export const orderVip = (obj) => {
  return request.post('/order/vip', {
    ...obj
  }, {
    timeout: 10000
  })
}

export const checkOrder = (platform, id) => {
  return request.get(`/order/check/${platform}/${id}`)
}

export const useQrCode = () => {
  return request.get('/recharge/use/qrcode')
}
