/**
 * 视频页相关请求
 */
import request from '@/utils/request'
// import store from '@/store'

// 请求影片详情
export const getMovie = (id) => {
  return request.get(`/movie/${id}`)
  // const userId = store.getters.userId || ''
  // return request.get(`/movie/${id}/${userId}`)
}

// 请求影片资源
// export const getMovieSrc = (id) => {
//   return request.get(`/movie/${id}/src`)
// }

// 请求影片评论
export const getMovieComments = (id, page) => {
  return request.get(`/movie/${id}/comments/${page}`)
}

// 请求推荐Top10
export const getMovieRecommend = (category, without) => {
  return request.post('/movie/top10', {
    category,
    without
  })
}

// 添加评论
export const addComment = (id, score, description) => {
  return request.post('/user/add/comment', {
    id,
    score,
    description
  })
}

// 设置收藏
export const setFavorite = (id, isFavorite) => {
  return request.post('/user/set/favorite', {
    id,
    isFavorite
  })
}

// 添加观看记录
export const addViewing = (id) => {
  return request.get(`/user/add/viewing/${id}`)
}

export const onExpend = (id, type = 1) => {
  return request.post('/user/expend', {
    id,
    type
  })
}
